var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card-group"
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row mb-3"
  }, [_vm._m(0), _vm._.isEmpty(_vm.currentGroup) || _vm.currentGroup.id ? _c('div', {
    staticClass: "col-md-6 text-right"
  }, [_c('b-button', {
    directives: [{
      name: "permission",
      rawName: "v-permission",
      value: 'organisation-product-spec-create',
      expression: "'organisation-product-spec-create'"
    }],
    attrs: {
      "variant": "outline-success"
    },
    on: {
      "click": function ($event) {
        return _vm.addNew();
      }
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "plus",
      "size": 18
    }
  }), _vm._v(" Add New Group ")], 1)], 1) : _vm._e()]), _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isLoading,
      expression: "isLoading"
    }]
  }, [_c('orgProductSpecGroupEdit', {
    ref: "productSpecEdit",
    on: {
      "orgProductSpecGroupReset": function ($event) {
        return _vm.setup();
      }
    }
  })], 1)])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-md-6"
  }, [_c('h4', {
    staticClass: "card-title"
  }, [_vm._v("Product Specification Groups")])]);
}]

export { render, staticRenderFns }