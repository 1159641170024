var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm._.isEmpty(_vm.currentGroup) ? _c('el-form', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isLoading,
      expression: "isLoading"
    }],
    ref: "orgProductSpecGroupForm",
    attrs: {
      "model": _vm.form,
      "label-width": "120px"
    }
  }, [_vm.group.id === undefined ? _c('el-form-item', {
    attrs: {
      "label": "Group Name",
      "prop": "name"
    }
  }, [_c('el-input', {
    model: {
      value: _vm.group.name,
      callback: function ($$v) {
        _vm.$set(_vm.group, "name", $$v);
      },
      expression: "group.name"
    }
  })], 1) : _vm._e(), _c('el-collapse', {
    model: {
      value: _vm.collapActiveNames,
      callback: function ($$v) {
        _vm.collapActiveNames = $$v;
      },
      expression: "collapActiveNames"
    }
  }, [_vm._l(_vm.availableSpecs, function (categories, index) {
    return [_vm.editable || _vm.countByCategory[categories.id] ? _c('el-collapse-item', {
      key: index,
      attrs: {
        "name": categories.id
      }
    }, [_c('template', {
      slot: "title"
    }, [_vm.countByCategory[categories.id] ? _c('el-badge', {
      staticClass: "categorycount",
      attrs: {
        "value": _vm.countByCategory[categories.id]
      }
    }, [_c('strong', {
      staticClass: "text-success"
    }, [_vm._v(_vm._s(categories.category))])]) : _c('strong', {
      staticClass: "text-success"
    }, [_vm._v(_vm._s(categories.category))])], 1), _c('div', {
      staticClass: "table-responsive"
    }, [_c('table', {
      staticClass: "table email-table no-wrap table-hover v-middle"
    }, [_c('tbody', [_vm._l(categories.specifications, function (spec, i) {
      return [_vm.editable || _vm.selections.includes.indexOf(spec.id) !== -1 ? _c('tr', {
        key: i
      }, [_c('td', [_c('input', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: _vm.selections.includes,
          expression: "selections.includes"
        }],
        attrs: {
          "disabled": _vm.editable ? false : 'disabled',
          "type": "checkbox"
        },
        domProps: {
          "value": spec.id,
          "checked": Array.isArray(_vm.selections.includes) ? _vm._i(_vm.selections.includes, spec.id) > -1 : _vm.selections.includes
        },
        on: {
          "change": [function ($event) {
            var $$a = _vm.selections.includes,
              $$el = $event.target,
              $$c = $$el.checked ? true : false;
            if (Array.isArray($$a)) {
              var $$v = spec.id,
                $$i = _vm._i($$a, $$v);
              if ($$el.checked) {
                $$i < 0 && _vm.$set(_vm.selections, "includes", $$a.concat([$$v]));
              } else {
                $$i > -1 && _vm.$set(_vm.selections, "includes", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
              }
            } else {
              _vm.$set(_vm.selections, "includes", $$c);
            }
          }, function (event) {
            return _vm.handleCheck(event, spec.id);
          }]
        }
      }), _c('label', {
        staticClass: "pl-2"
      }, [_vm._v("Include")])]), _c('td', [_c('input', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: _vm.selections.mandatories,
          expression: "selections.mandatories"
        }],
        attrs: {
          "disabled": _vm.editable ? false : 'disabled',
          "type": "checkbox"
        },
        domProps: {
          "value": spec.id,
          "checked": Array.isArray(_vm.selections.mandatories) ? _vm._i(_vm.selections.mandatories, spec.id) > -1 : _vm.selections.mandatories
        },
        on: {
          "change": [function ($event) {
            var $$a = _vm.selections.mandatories,
              $$el = $event.target,
              $$c = $$el.checked ? true : false;
            if (Array.isArray($$a)) {
              var $$v = spec.id,
                $$i = _vm._i($$a, $$v);
              if ($$el.checked) {
                $$i < 0 && _vm.$set(_vm.selections, "mandatories", $$a.concat([$$v]));
              } else {
                $$i > -1 && _vm.$set(_vm.selections, "mandatories", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
              }
            } else {
              _vm.$set(_vm.selections, "mandatories", $$c);
            }
          }, function (event) {
            return _vm.handleCheck(event, spec.id, 'mandatories');
          }]
        }
      }), _c('label', {
        staticClass: "pl-2"
      }, [_vm._v("Mandatory")])]), _c('td', [_vm._v(" " + _vm._s(spec.name)), _c('br'), _c('span', {
        staticClass: "small"
      }, [_vm._v(_vm._s(spec.description))])])]) : _vm._e()];
    })], 2)])])], 2) : _vm._e()];
  })], 2), _c('div', {
    staticClass: "mt-4"
  }, [_vm._t("default")], 2)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }