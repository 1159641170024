var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-card', {
    staticClass: "box-card"
  }, [_vm._.isEmpty(_vm.currentGroup) || _vm.currentGroup.id !== undefined ? _c('div', {
    staticClass: "clearfix",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('span', [_vm._v(" Select a group: "), _c('el-select', {
    staticClass: "white-out",
    attrs: {
      "placeholder": "Select"
    },
    on: {
      "change": _vm.setForm
    },
    model: {
      value: _vm.groupSelect,
      callback: function ($$v) {
        _vm.groupSelect = $$v;
      },
      expression: "groupSelect"
    }
  }, _vm._l(_vm.availableGroups, function (item) {
    return _c('el-option', {
      key: item.id,
      attrs: {
        "label": item.name,
        "value": item.id
      }
    });
  }), 1)], 1)])])]) : _vm._e(), _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isLoading,
      expression: "isLoading"
    }]
  }, [_c('OrgProductSpecGroupEditorForm', {
    ref: "orgProductSpecGroupEditorForm"
  }, [[!_vm.editable ? _c('div', [_c('b-button', {
    directives: [{
      name: "permission",
      rawName: "v-permission",
      value: 'organisation-product-spec-edit',
      expression: "'organisation-product-spec-edit'"
    }],
    staticClass: "mr-3",
    attrs: {
      "variant": "outline-success"
    },
    on: {
      "click": function ($event) {
        return _vm.enableEdit();
      }
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "pencil-lock-outline",
      "size": 18
    }
  }), _vm._v(" Edit")], 1)], 1) : _c('div', [_c('b-button', {
    staticClass: "mr-3",
    attrs: {
      "variant": "outline-success"
    },
    on: {
      "click": function ($event) {
        return _vm.onSubmit();
      }
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "content-save",
      "size": 18
    }
  }), _vm._v(" Save")], 1), _c('b-button', {
    staticClass: "mr-3",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function ($event) {
        return _vm.reset();
      }
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "refresh",
      "size": 18
    }
  }), _vm._v(" Reset")], 1), _vm.currentGroup.id ? _c('b-button', {
    directives: [{
      name: "permission",
      rawName: "v-permission",
      value: 'organisation-product-spec-delete',
      expression: "'organisation-product-spec-delete'"
    }],
    attrs: {
      "variant": "outline-danger"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.confirmDelete();
      }
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "trash-can",
      "size": 18
    }
  }), _vm._v(" Delete")], 1) : _c('b-button', {
    attrs: {
      "variant": "outline-danger"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.cancel();
      }
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "close-circle-outline",
      "size": 18
    }
  }), _vm._v(" Cancel")], 1)], 1)]], 2)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }